<template>
  <div>
    <p>Loading Prismic Preview...</p>
  </div>
</template>
<script>
export default {
  name: 'PrismicPreview',
  beforeCreate() {
    const previewToken = this.$route.query.token;

    this.$prismic.client.previewSession(previewToken, this.$prismic.linkResolver, '/')
      .then((url) => {
        window.location.replace(url);
      });
  }
};
</script>
