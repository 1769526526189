import store from '@/store';
import type { UserSettingsKey } from '@/types';
import type { NavigationGuard } from 'vue-router';

const restoreQueryFromSettings = (userSettingsKey: UserSettingsKey) => {
    const beforeEnter: NavigationGuard = async (to, from, next) => {
        const { path, query } = to;
        const noQueryGiven = Object.keys(query).length === 0;
        if (noQueryGiven) {
            await store.state.account.currentSessionCheck;
            try {
                const storedQuery = JSON.parse(store.state.account.currentUser?.userSettings?.[userSettingsKey] || '{}');
                const hasStoredQuery = Object.keys(storedQuery).length !== 0;
                if (hasStoredQuery) {
                    next({ path, query: storedQuery });
                } else {
                    next();
                }
            } catch (error) {
                next();
            }
        } else {
            next();
        }
    };
    return beforeEnter;
};

export default restoreQueryFromSettings;
