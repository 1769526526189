import store from '@/store';
import type { NavigationGuard } from 'vue-router';
import RouteNames from './names';

const signedInGuard: NavigationGuard = async (to, from, next) => {
    await store.state.account.currentSessionCheck;
    const isSignedIn = Boolean(store.state.account.currentUser);

    if (isSignedIn) {
        next();
    } else {
        next({ name: RouteNames.REGISTER, query: { redirect: to.fullPath } });
    }
}

const clientGuard: NavigationGuard = async (to, from, next) => {
    await store.state.account.currentSessionCheck;
    const hasClientGroup = Boolean(store.state.account.currentUser?.clientGroups?.length ?? 0 > 0);

    if (hasClientGroup) {
        next();
    } else {
        next({ name: RouteNames.HOME });
    }
}

const multipleGuards = (guards: NavigationGuard[]) => {
    const multipleGuards: NavigationGuard = async (to, from, next) => {
        let calledNextWithArgs = false;

        function trackedNext(...args: Parameters<typeof next>) {
            if (args.length !==0) {
                calledNextWithArgs = true;
                next(...args);
            }
        }

        for (const guard of guards) {
            await guard(to, from, trackedNext);
            if (calledNextWithArgs) return;
        }

        next();
    }

    return multipleGuards;
}

export default signedInGuard;
export { clientGuard, multipleGuards };
