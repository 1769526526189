import { RouteConfig } from 'vue-router';
import RouteNames from './names';
import openExternalLink from './open-external-link';

const PRISMIC_ROOT = 'https://prismic-io.s3.amazonaws.com/iseechange';

// These routes are normally handled by Netlify, but reimplemented here for the native apps.
const externalRedirects: RouteConfig[] = [
    {
        path: '/share/sightings/:id',
        redirect: ({ params }) => ({ name: RouteNames.POST_DETAIL, params }),
    },
    {
        path: '/share/projects/:organizationSlug/:projectSlug',
        redirect: ({ params }) => ({ name: 'project', params }),
    },
    {
        path: '/careers*',
        beforeEnter() {
            openExternalLink('https://iseechange.notion.site/Jobs-at-ISeeChange-120b4c78301442169fdd104d814b1a4e', true);
        },
    },
    {
        path: '/hurricanemilton*',
        beforeEnter() {
            openExternalLink('https://iseechange.notion.site/Hurricane-Milton-Storm-Resources-118d7951f7b28059b34ad38bc8ab2956', true);
        },
    },
    {
        path: '/work-with-us*',
        beforeEnter() {
            openExternalLink('https://partners.iseechange.com/', true);
        },
    },
    {
        path: '/ISeeChange_Harlem_Heat_CaseStudy.pdf',
        beforeEnter() {
            openExternalLink(`${PRISMIC_ROOT}/bd6daea3-8161-4763-9fd9-c8a1afe23efb_iseechange_harlem_heat_casestudy.pdf`, true);
        }
    },
    {
        path: '/ISeeChange_NewOrleans_Flooding_CaseStudy.pdf',
        beforeEnter() {
            openExternalLink(`${PRISMIC_ROOT}/060a165f-84b2-4cd1-b34b-a2c81fac9c48_ISeeChange_NewOrleans_Flooding_CaseStudy1.pdf`, true);
        }
    },
];

export default externalRedirects;
