import { Device } from '@capacitor/device';

export default async function openExternalLink(href: string, replace = false) {
    const { platform } = await Device.getInfo();

    if (platform === 'web') {
        location[replace ? 'replace' : 'assign'](href);
    } else {
        open(href);
    }
}
